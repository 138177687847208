import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Green Pitch Fields
			</title>
			<meta name={"description"} content={"Your local football haven"} />
			<meta property={"og:title"} content={"Green Pitch Fields"} />
			<meta property={"og:description"} content={"Your local football haven"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/2-3.jpg?v=2024-02-14T12:43:28.111Z"} />
		</Helmet>
		<Components.Header2 />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12:43:28.118Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-4%20%281%29.jpg?v=2024-02-14T12%3A43%3A28.118Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					About Us
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Green Pitch Fields is a beacon for local football enthusiasts, offering a space where the spirit of the game thrives. We don't just rent out fields, we are a hub for community, unity for common goals, and the simple joy of playing football. Our foundation is built on the belief that football is more than just a game, it is a unifier that brings people together from all walks of life.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					To Services
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					The Essence of Green Pitch Fields
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Green Pitch Fields was created out of a love for the game and the community it fosters, to provide high-quality and affordable football fields for everyone. Our essence lies in creating an environment where every kick of the ball contributes to building friendships, competition, and a deep love for football.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--base">
					<Strong>
						{" "}Here, the passion for the game echoes with every cheer and every shout of support.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Impeccable Playing Conditions
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Our commitment to maintaining the highest quality fields ensures optimal playing conditions that meet professional standards.
					<br />
					<br />
					We welcome players of all ages, backgrounds, and skill levels, reinforcing the idea that football is for everyone.
					<br />
					By offering affordable rental rates, we ensure that financial barriers do not hinder the love of the game, making it accessible to everyone who wants to play.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12:43:28.098Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7c3d8d4a0c00202ba192/images/3-3.jpg?v=2024-02-14T12%3A43%3A28.098Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Frequently Asked Questions
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px \n\n50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							How early should I book a spot?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							To secure your desired time slot, we recommend booking as early as possible. Our fields are in high demand, especially during peak hours and weekends.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Can I cancel or reschedule my booking?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							While we understand that plans may change, we ask that you notify us of any cancellations or rescheduling as soon as possible so we can accommodate other players.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Do you offer coaching sessions?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							While our primary service is field rental, we do occasionally collaborate with local coaches to offer clinics and sessions aimed at skill development. Keep an eye on our announcements for upcoming events.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Is there a place for spectators?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, we have designated areas for spectators to comfortably enjoy the game. Whether you're cheering for your friends or watching a local tournament, we have the perfect spot for you.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});